.messenger {

  display: flex;
  justify-content: center;
}

.chatMenu {
  flex: 3.5;
}

.chatMenuInput {
  width: 90%;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid gray;
}

.chatBox {
  flex: 5.5;
}

.chatBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.chatBoxTop {
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
}

.chatBoxBottom {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatMessageInput {
  width: 80%;
  height: 90px;
  padding: 10px;
}

.chatSubmitButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: teal;
  color: white;
}

.chatOnline {
  flex: 3;
}

.chatMenuWrapper,
.chatBoxWrapper,
.chatOnlineWrapper {
  padding: 10px;
  height: 100%;
}

.noConversationText {
  position: absolute;
  top: 10%;
  font-size: 50px;
  color: rgb(224, 220, 220);
  cursor: default;
}

@media screen and (max-width: 768px) {
  .chatMenu {
    flex: 1;
  }

  .chatMenuInput {
    display: none;
  }

  .chatBox {
    flex: 10;
  }

  .chatOnline {
    flex: 1px;
  }
}


@keyframes squeeze {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

/* Messenger Component */
.messenger {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.user-list {
  flex: 1;
  background-color: #f2f2f2;
  padding: 20px;
}

.message-section {
  flex: 3;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
}

/* User List */
.user-list .list-group-item {
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.user-list .list-group-item:hover {
  background-color: #eaeaea;
}

.user-list .user-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-list .username {
  font-weight: bold;
}

.user-list .last-message {
  font-size: 12px;
  color: #777;
}

/* Message Section */
.message-section .message-header {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.message-section .message-header .user-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-section .message-header .username {
  font-weight: bold;
}

.message-section .message-header .online-status,
.message-section .message-header .offline-status {
  font-size: 12px;
  color: #777;
}

.message-section .message-body {
  flex: 1;
  overflow-y: auto;
  padding: 10px 0;
}

.message-section .message-body .message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message-section .message-body .message.outgoing {
  align-items: flex-end;
}

.message-section .message-body .message .content {
  background-color: #e2f3ff;
  padding: 10px;
  border-radius: 5px;
}

.message-section .message-body .message .time {
  font-size: 12px;
  color: #777;
}

.message-section .message-footer {
  padding-top: 10px;
}

.message-section .message-footer .alert {
  margin-bottom: 10px;
}

.message-section .message-footer form .form-group {
  margin-bottom: 10px;
}

/* Empty Message */
.empty-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #777;
  font-size: 18px;
}
/********************/

/* .sendNewMessage {
  background-color: rgb(235, 235, 235);
  border: 3px solid #d0d0d0;
  width: 60%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  background-color: rgb(190, 176, 189);
}

.sendNewMessagedark {
  background-image: linear-gradient(to right, rgb(24, 17, 17), rgb(85 6 101));
  border: 2px solid #d0d0d0;
  width: 61%;
  padding: 3px;
  border-radius: 8px;
  color: white;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendNewMessage button {
  width: 36px;
  height: 36px;
  background-color: #ecefff;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: rgb(0, 50, 77);
  padding: 0;
  border: 3px solid #d0d0d0;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}
.copied_status
{
  color: red;
  background-color: cornsilk;
  position: absolute;
  bottom: 64px;
}

.sendNewMessagedark button {
  width: 36px;
  height: 36px;
  background-color: #ecefff;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: rgb(0, 50, 77);
  padding: 0;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}

@media (max-width: 864px) {
  .sendNewMessage, .sendNewMessagedark{
    width: 97%;
  }
}

.sendNewMessagedark > .darkButton {
  color: black !important;
}
.sendNewMessagedark > .darkButtonSend {
  background-color: white !important;
  background-color: white !important;
  color: black !important;
}

@media (max-width: 864px) {
  .sendNewMessage, .sendNewMessagedark{
    width: 97%;
  }
}

.sendNewMessage > .darkButton {
  color: black !important;
}
.sendNewMessage > .darkButtonSend {
  background-color: white !important;
  background-color: white !important;
  color: black !important;
}

.addfiles {
  margin-right: 5px;
}

.sendNewMessage button:hover {
  transform: scale(1.2);
}
.sendNewMessage button i {
  display: block;
}
.sendNewMessage input {
  flex-grow: 1;
  padding: 0 15px;
  background-color: transparent;
  border: none;
  outline: none;
}

.footer_dark .sendNewMessage {
  background-color: black;
}

.footer_dark input,
.footer_dark ::placeholder {
  color: white;
  border: none;
  background: none;
}
#sendMsgBtn {
  background-color: rgb(0, 50, 77);
  color: #fff;
}

@media (max-width: 700px) {
  .sendNewMessage, .sendNewMessagedark{
    width: 98.5%;
    max-width: 700px;
  }
}

@media (max-width: 790px) {
  .sendNewMessage, .sendNewMessagedark{
    width: 95%;
  }
}

@media (max-width: 430px) {
  .sendNewMessage, .sendNewMessagedark{
    width: 90%;
    margin-right: 8px;
  }
} */