.post {
    width: 100%;
    border-radius: 10px;


}




.overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.sidebar.open {
    transform: translateX(0);
}

.sidebar .overlay {
    opacity: 1;
    pointer-events: auto;
}



.overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #b65a5a;
    font-size: x-small;

}

.media-wrapper {
    position: relative;
    z-index: 0;
    max-width: fit-content;
}

.media {
    width: 100%;
    height: auto;
}





.cardside {
    width: auto;
    overflow: hidden;


    border-radius: 5px;

    background-color: #f4f4f4;
}

.postWrapper {
    
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;

}

.postTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.postTopLeft {
    display: flex;
    align-items: center;

}

.postProfileImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.postUsername {
    font-size: 0.83em;
    font-weight: 700;

}

.postDate {
    font-size: 12px;
}

.postCenter {
    margin: 20px 0;
}

.video-player {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* Aspect ratio 16:9 (adjust as needed) */
}

.video-player video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #fff;
    font-size: 60px;
    cursor: pointer;
}



.video-player video:focus+.play-icon {
    display: none;
}

.sidebare {
    position: absolute;
    top: 20;
    right: 0;
    width: min-content;

    height: 100%;
    background-color: #00000000;
    z-index: 100;
    transition: transform 0.3s ease;
    right: 0;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;


}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the overlay color and transparency as needed */
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.DiscoverStory {
    text-shadow: 0 1px 4px rgba(0, 0, 0, .6);
    margin: 0;
    align-self: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.contextCard {
    width: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    padding: 25px;
    color: white;

}

.postImg {

    width: 360px;
    height: 640px;

    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 90vh;
    border-radius: 20px;
    background: black;
}


.postBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.postBottomLeft {
    display: flex;
    /* align-items: center; */
    margin: 1px 10px;
    /* flex-direction: column; */
    align-items:  flex-start;
}

.likeIcon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    cursor: pointer;
}

.postLikeCounter {
    font-size: 15px;
}

.postCommentText {
    cursor: pointer;
    border-bottom: 1px dashed rgb(80, 93, 84);
    font-size: 15px;
}

.listOfComments {
    padding-left: 0.5rem;
    display: block;
    width: auto;
    margin: auto;
    height: auto;

    outline: none;
    transition: .3s;
    border: 0.5px solid rgb(185, 181, 181);

}

.white {
    color: rgb(255, 255, 255);
    cursor: pointer;
    padding-left: 0.5rem;
    margin-bottom: 0.5rem;
    transition: .3s;
    background-color: none;
}

.black {
    color: black;
}

.red {
    color: crimson;
}

.green {
    color: chartreuse;
}

.postProfileVideo {
    margin-right: 0px;
    width: 147px;
    margin-bottom: 16px;
    cursor: pointer;
    margin: 20px 5px;
    border-radius: 20px;

    transition: .25s ease;
    border: 0;
}


.post {
    width: auto;
    overflow: hidden;
    margin: 1rem auto;

    border-radius: 5px;

    background-color: #f4f4f4;

}

@media (max-height: 818px) {
    .postImg {
        height: 80vh;
        width: calc((9 * 80vh) / 16);
        max-width: min(100%, 460px);
    }
}


@media screen and (max-width: 800px) {
    .topbarLeft img {
        display: none;
    }

    .card {
        width: auto;
    }

}

#comm {
    display: block;
    text-align: center;
    min-width: -webkit-fill-available;
    margin: auto;
    height: 40px;
}

.shareIcon {
    font-size: 18px;
    margin-right: 3px;
}