.homeContainer {
    display: flex;
    width: auto;
    overflow: auto;

}

@media (max-width: 767px) {
    .homeContainer {
        flex-direction: column;

    }

    .homeContainer .sid {

        display: none;

    }

    .homeContainer .right {
        display: none;
    }

}