.App {
    text-align: center;
  }
  body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
  }
  body {
    background-attachment: fixed;
  }